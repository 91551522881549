<template>
   <div class="container">
      <section class="section section--head">
         <div class="container">
            <div class="row">
               <div class="col-12 col-xl-6">
                  <h1 class="section__title section__title--head">الملف الشخصي</h1>
               </div>


            </div>
         </div>
      </section>
      <div class="catalog catalog--page">
         <div class="container">
            <div class="row">
               <div class="col-12">
                  <div class="profile">
                     <div class="profile__user">
                        <div class="profile__avatar">
                           <i class="bi bi-person-square"></i>
                        </div>
                        <div class="profile__meta">
                           <h3 v-if="showName">{{ name }}</h3>
                           <h3 v-else>{{ name }}</h3>

                           <span v-if="showMDN"> {{ mdn }}</span>
                           <span v-else> {{ mdn }}</span>
                        </div>
                     </div>

                     <!-- tabs nav -->
                     <ul class="nav nav-tabs profile__tabs" id="profile__tabs" role="tablist">
                        <li class="nav-item">
                           <a class="nav-link active" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1"
                              aria-selected="true">الاشتراكات</a>
                        </li>

                        <li class="nav-item">
                           <a class="nav-link" data-toggle="tab" href="#tab-3" role="tab" aria-controls="tab-3"
                              aria-selected="false">الاعدادات</a>
                        </li>
                     </ul>
                     <!-- end tabs nav -->


                  </div>
               </div>
            </div>
         </div>

         <div class="container">
            <!-- content tabs -->
            <div class="tab-content">
               <div class="tab-pane fade show active" id="tab-1" role="tabpanel">
                  <div class="row row--grid">
                     <!-- stats -->
                     <div class="col-12 col-sm-6 col-xl-3">
                        <div class="stats">
                           <span> منصة نشوف </span>
                           <a  class="sign__btn" style="background-image: linear-gradient(0deg, #710f0fd4 0, #c94200);" v-if="this.$cookie.isCookieAvailable('mdn') " @click.prevent="unsubscribe()">الغاء الاشتراك</a>
                              <a class="sign__btn" href="https://dsplp.sd.zain.com/?p=3835525433" v-else> إشترك الان</a>
                        </div>
                     </div>
                     <!-- end stats -->

                     <!-- stats -->


                    



                  </div>
               </div>



               <div class="tab-pane fade" id="tab-3" role="tabpanel">
                  <div class="row">
                     <div class="col-12">
                        <div class="sign__wrap">
                           <div class="row">
                              <!-- details form -->
                              <div class="col-12 col-lg-6">
                                 <form v-on:submit.prevent="onSend()" class="sign__form--profile sign__form--first">
                                    <div class="row">
                                       <div class="col-12">
                                          <h4 class="sign__title">تفاصيل الملف</h4>
                                       </div>

                                       <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                                          <div class="sign__group">
                                             <label class="sign__label" for="username">الاسم كامل</label>
                                             <input id="username" type="text" v-model="username" name="username"
                                                class="sign__input" placeholder="الاسم كامل">
                                          </div>
                                       </div>

                                       <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                                          <div class="sign__group">
                                             <label class="sign__label" for="email">Email</label>
                                             <input id="email" type="text" v-model="email" name="email" class="sign__input"
                                                placeholder="email@email.com">
                                          </div>
                                       </div>



                                       <div class="col-12">
                                          <button class="sign__btn" type="submit">حفظ</button>
                                       </div>
                                    </div>
                                 </form>
                              </div>
                              <!-- end details form -->

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- end content tabs -->
         </div>
      </div>
   </div>
</template>
 
<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import config from '@/Api/config';

export default {
   name: 'Profiles',
   data() {
      return {
         username: "",
         email: "",
      };
   },
   beforeCreate: function () {
      if (this.$cookie.isCookieAvailable("mdn")) {
         this.showMDN = true;
         this.mdn = this.$cookie.getCookie("mdn");
      } else {
         this.showMDN = false;
         this.mdn = "XXX XXXX";
      }
      if (this.$cookie.isCookieAvailable("full_name")) {
         this.showName = true;
         this.name = this.$cookie.getCookie("full_name");
      } else {
         this.showName = false;
         this.name = "مستخدم جديد";
      }
   },
   methods: {

      // https://nashoof.sd.zain.com/NashoofAP_I/APIs/UpdateUserInfo.php?username=249912390141&full_name=Mohamed&email=mo7a@gm.com

      onSend() {


         // 

         const toast = useToast();


         // ////////////////////////////////////////////////////////
         if (this.username != "" && this.email != "") {
            if (this.$cookie.isCookieAvailable("full_name")) {
               axios.get(
                  "https://nashoof.sd.zain.com/NashoofAP_I/APIs/UpdateUserInfo.php?username=" + this.$cookie.getCookie("mdn") + "&full_name=" + this.username + "&email=" + this.email
                  , {
                     headers: {
                        'Authorization': config.authHeader,
                     },
                  }).then((response) => {
                     if (response.data.status == 1) {
                        this.$cookie.setCookie('full_name', this.username);

                        toast.success("تم التحديث بنجاح", {
                           timeout: 4000
                        });
                        setTimeout(() =>
                           this.$router.push({
                              path: "/Profile"
                           }),
                           4000
                        );
                     } else {
                        toast.error("لم يتم الارسال", {
                           timeout: 4000
                        });

                     }
                  })

            } else {
               axios.get(
                  "https://nashoof.sd.zain.com/NashoofAP_I/APIs/AddNewUser.php?username=" + this.$cookie.getCookie("mdn") + "&full_name=" + this.username + "&email=" + this.email
                  , {
                     headers: {
                        'Authorization': config.authHeader,
                     },
                  }).then((response) => {
                     if (response.data.status == 1) {
                        this.$cookie.setCookie('full_name', this.username, {
                           expire: 60 * response.data.remming_minutes,
                        })
                        toast.success("تم إضافة بياناتك بنجاح", {
                           timeout: 4000
                        });
                        setTimeout(() =>
                           this.$router.push({
                              path: "/Profile"
                           }),
                           4000
                        );
                     } else {
                        toast.info("سيتم تحديث البيانات المستخدم مضاف مسبقا", {
                           timeout: 4000
                        });



                        axios.get(
                           "https://nashoof.sd.zain.com/NashoofAP_I/APIs/getUserInfo.php?username=" + this.$cookie.getCookie("mdn")
                           , {
                              headers: {
                                 'Authorization': config.authHeader,
                              },
                           }).then((response) => {
                              if (response.data.UserInfo[0].full_name != null) {
                                 axios.get(
                                    "https://nashoof.sd.zain.com/NashoofAP_I/APIs/UpdateUserInfo.php?username=" + this.$cookie.getCookie("mdn") + "&full_name=" + this.username + "&email=" + this.email
                                    , {
                                       headers: {
                                          'Authorization': config.authHeader,
                                       },
                                    }).then((response) => {
                                       if (response.data.status == 1) {
                                          this.$cookie.setCookie('full_name', this.username);

                                          toast.success("تم التحديث بنجاح", {
                                             timeout: 4000
                                          });
                                          setTimeout(() =>
                                             this.$router.push({
                                                path: "/Profile"
                                             }),
                                             4000
                                          );
                                       } else {
                                          toast.error("لم يتم الارسال", {
                                             timeout: 4000
                                          });

                                       }
                                    })

                                 // this.$cookie.setCookie('full_name', response.data.UserInfo[0].full_name);

                              } else {
                                 return

                              }
                           })
                        setTimeout(() =>
                           this.$router.push({
                              path: "/Profile"
                           }),
                           4000
                        );
                     }
                  })

            }






         } else {
            // If invalid, show error and clear the formatted number
            toast.info("بعض الحقول فارغة ");
         }


         // /////////////////////////////////////////////////////


      },

      unsubscribe: function () {

let loader = this.$loading.show({
    // Optional parameters
    container: this.oldNum ? null : this.$refs.formContainer,
    // canCancel: true,
    // onCancel: this.onCancel,
});
// simulate AJAX
setTimeout(() => loader.hide(), 3 * 1000)

const toast = useToast();
axios
    .get("https://nashoof.sd.zain.com/DSPAPIs/APIs/DSPUnsubscribe.php?msisdn=" + this.$cookie.getCookie("mdn") , {
        headers: {
            'Authorization': config.authHeader,
        },
    })
    .then((response) => {
        if (response.data.status == 1) {
            //   this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            //   this.$cookie.removeCookie("mdn");
            this.$cookie.removeCookie("Content_id");
            this.$cookie.removeCookie("minutes");
            this.$cookie.removeCookie("Catogery_id");
            this.$cookie.removeCookie("product_code");

            toast.success("لقد تم الغاء اشتراكك من  نشوف ", {
                timeout: 3000
            })

            this.$router.push("/");
            this.$router.go()
        } else {
            toast.error("عفوا ليست لديك اشتراك في الخدمة  ", {
                timeout: 3000
            })
        }
    })
}
   }
}
</script>
 
<style></style>